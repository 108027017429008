<template>
  <div>
    <!-- Form: Personal Info Form -->
    <b-form class="mt-1">
      <b-row>

        <!-- Field: Phone -->
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="Mobile Phone"
            label-for="phone"
          >
            <b-form-input
              id="phone"
              v-model="userData.profile.phone"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Personal Emal -->
        <!-- <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="Personal Email"
            label-for="personal-email"
          >
            <b-form-input
              id="personal-email"
              type="email"
              v-model="userData.profile.personal_email"
            />
          </b-form-group>
        </b-col> -->

        <!-- Field: Home Address -->
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="Home Address"
            label-for="home-address"
          >
            <b-form-input
              id="home-address"
              v-model="userData.profile.home_address"
            />
          </b-form-group>
        </b-col>

      </b-row>

      <b-row class="mt-1">

        <!-- Field: Company -->
        <b-col
          cols="12"
          md="6"
          lg="2"
        >
          <b-form-group
            label="Company Name"
            label-for="company-name"
          >
            <b-form-input
              id="company-name"
              v-model="userData.profile.company"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Brand Name -->
        <b-col
          cols="12"
          md="6"
          lg="2"
        >
          <b-form-group
            label="Brand Name"
            label-for="brand-name"
          >
            <b-form-input
              id="brand-name"
              v-model="userData.profile.brand_name"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Company Address -->
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="Company Address"
            label-for="company-address"
          >
            <b-form-input
              id="company-address"
              v-model="userData.profile.company_address"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Company Country -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Company Country"
            label-for="company_country"
          >
            <v-select
              v-model="userData.company_country_id"
              :options="countries"
              :reduce="val => val.value"
              :clearable="true"
              @input="userData.company_state_id = null; userData.company_city_id = null; loadStates(userData.company_country_id);"
              input-id="company-country"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Company Province -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Company Province"
            label-for="company_state"
          >
            <v-select
              v-model="userData.company_state_id"
              :options="states"
              :reduce="val => val.value"
              :clearable="true"
              input-id="company-state"
              @input="userData.company_city_id = null; loadCities(userData.company_state_id)"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Company City -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Company City"
            label-for="company_city"
          >
            <v-select
              v-model="userData.company_city_id"
              :options="cities"
              :reduce="val => val.value"
              :clearable="true"
              input-id="company-city"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Office Phone -->
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="Office Phone"
            label-for="company-phone"
          >
            <b-form-input
              id="company-phone"
              v-model="userData.profile.company_phone"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Office Email -->
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="Office Email"
            label-for="company-email"
          >
            <b-form-input
              id="company-phone"
              type="email"
              v-model="userData.profile.company_email"
            />
          </b-form-group>
        </b-col>


        <!-- Field: Job Title -->
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="Job Title"
            label-for="company-position"
          >
            <v-select
              v-model="userData.profile.position"
              :options="job_titles"
              :reduce="val => val.value"
              :clearable="true"
              input-id="job-title"
              @input="userData.profile.position_others = (userData.profile.position == 'Other') ? '' : userData.profile.position;"
            />

            <b-form-input
              type="text"
              class="mt-1"
              v-model="userData.profile.position_others"
              placeholder="Other job title"
              v-if="userData.profile.position == 'Other'"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Job Level -->
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="Job Level"
            label-for="job-level"
          >

            <v-select
              v-model="userData.profile.job_level"
              :options="job_levels"
              :reduce="val => val.value"
              :clearable="true"
              input-id="job-level"
              @input="userData.profile.job_level_others = (userData.profile.job_level == 'Other') ? '' : userData.profile.job_level;"
            />

            <b-form-input
              type="text"
              class="mt-1"
              v-model="userData.profile.job_level_others"
              placeholder="Other job level"
              v-if="userData.profile.job_level == 'Other'"
            />

          </b-form-group>
        </b-col>

      </b-row>

      <b-row class="mt-1">

        <!-- Field: Industry -->
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="Company Industry"
            label-for="company-industry"
          >

            <v-select
              v-model="userData.profile.industry"
              :options="industries"
              :reduce="val => val.value"
              :clearable="true"
              input-id="company-industry"
              @input="userData.profile.industry_others = (userData.profile.industry == 'Other') ? '' : userData.profile.industry;"
            />

            <b-form-input
              type="text"
              class="mt-1"
              v-model="userData.profile.industry_others"
              v-if="userData.profile.industry == 'Other'"
              placeholder="Other industry name"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Industry -->
        <b-col
          cols="12"
          md="6"
          lg="4"
        >
          <b-form-group
            label="Company Size"
            label-for="company-size"
          >

            <v-select
              v-model="userData.profile.company_size"
              :options="company_sizes"
              :reduce="val => val.value"
              :clearable="true"
              input-id="company-size"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row class="mt-2">
        <b-col>
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :disabled="!isValid()"
            @click="save()"
          >
            <feather-icon
              icon="SaveIcon"
              class="mr-50"
            />
            <span>{{ (isLoading) ? "Loading..." : "Save" }}</span>
          </b-button>
          <!-- <b-button
            variant="outline-secondary"
            @click="resetForm()"
          >
            Reset
          </b-button> -->
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import {
  BRow, BCol, BForm, BFormGroup, BFormInput, BFormRadioGroup, BFormCheckboxGroup, BButton,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BRow, BCol, BForm, BFormGroup, flatPickr, BFormInput, vSelect, BFormRadioGroup, BFormCheckboxGroup, BButton,
  },
  props: {
    userData: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      isLoading: false,
      cities: [],
      countries: [],
      states: [],
      job_level_values: [],
      industry_values: [],
      job_title_values: [],
      job_levels: [
        // {
        //   label: "C-Level",
        //   value: "C-Level"
        // },
        // {
        //   label: "Vice President",
        //   value: "Vice President"
        // },
        // {
        //   label: "Director",
        //   value: "Director"
        // },
        // {
        //   label: "Manager",
        //   value: "Manager"
        // },
        // {
        //   label: "Consultant",
        //   value: "Consultant"
        // },
        // {
        //   label: "Specialist",
        //   value: "Specialist"
        // },
        // {
        //   label: "Student",
        //   value: "Student"
        // },
      ],
      job_titles: [
        // {
        //   label: "Administration",
        //   value: "Administration"
        // },
        // {
        //   label: "Compliance / Risk",
        //   value: "Compliance / Risk"
        // },
        // {
        //   label: "Finance",
        //   value: "Finance"
        // },
        // {
        //   label: "General Management",
        //   value: "General Management"
        // },
        // {
        //   label: "Human Resources",
        //   value: "Human Resources"
        // },
        // {
        //   label: "IT - General",
        //   value: "IT - General"
        // },
        // {
        //   label: "IT - Data Center",
        //   value: "IT - Data Center"
        // },
        // {
        //   label: "IT - Networking",
        //   value: "IT - Networking"
        // },
        // {
        //   label: "IT - Security",
        //   value: "IT - Security"
        // },
        // {
        //   label: "IT - Servers",
        //   value: "IT - Servers"
        // },
        // {
        //   label: "IT - Software",
        //   value: "IT - Software"
        // },
        // {
        //   label: "IT - Storage",
        //   value: "IT - Storage"
        // },
        // {
        //   label: "Operations",
        //   value: "Operations"
        // },
        // {
        //   label: "Purchasing / Procurement",
        //   value: "Purchasing / Procurement"
        // },
        // {
        //   label: "Sales / Marketing",
        //   value: "Sales / Marketing"
        // },
      ],
      industries: [
        // {
        //   label: "Aerospace & Defense",
        //   value: "Aerospace & Defense"
        // },
        // {
        //   label: "Agriculture & Forestry",
        //   value: "Agriculture & Forestry"
        // },
        // {
        //   label: "Arts, Entertainment & Recreation",
        //   value: "Arts, Entertainment & Recreation"
        // },
        // {
        //   label: "Construction",
        //   value: "Construction"
        // },
        // {
        //   label: "Education",
        //   value: "Education"
        // },
        // {
        //   label: "Education - Higher",
        //   value: "Education - Higher"
        // },
        // {
        //   label: "Financial Services (Banking / Insurance / Multifinance / Fintech)",
        //   value: "Financial Services (Banking / Insurance / Multifinance / Fintech)"
        // },
        // {
        //   label: "Government - Federal / National",
        //   value: "Government - Federal / National"
        // },
        // {
        //   label: "Government - State / Local",
        //   value: "Government - State / Local"
        // },
        // {
        //   label: "Healthcare",
        //   value: "Healthcare"
        // },
        // {
        //   label: "Hospitality",
        //   value: "Hospitality"
        // },
        // {
        //   label: "Manufacturing",
        //   value: "Manufacturing"
        // },
        // {
        //   label: "Media",
        //   value: "Media"
        // },
        // {
        //   label: "Mining/Oil/Gas",
        //   value: "Mining/Oil/Gas"
        // },
        // {
        //   label: "Pharmaceutical",
        //   value: "Pharmaceutical"
        // },
        // {
        //   label: "Professional Services",
        //   value: "Professional Services"
        // },
        // {
        //   label: "Reseller/Integrator",
        //   value: "Reseller/Integrator"
        // },
        // {
        //   label: "Retail",
        //   value: "Retail"
        // },
        // {
        //   label: "Technology",
        //   value: "Technology"
        // },
        // {
        //   label: "Telecommunications",
        //   value: "Telecommunications"
        // },
        // {
        //   label: "Transportation & Logistics",
        //   value: "Transportation & Logistics"
        // },
        // {
        //   label: "Utility",
        //   value: "Utility"
        // },
        // {
        //   label: "Wholesale",
        //   value: "Wholesale"
        // },
      ],
      company_sizes: [
      //   {
      //     label: "< 10",
      //     value: "<10"
      //   },
      //   {
      //     label: "10 - 99",
      //     value: "10-99"
      //   },
      //   {
      //     label: "100 - 249",
      //     value: "100-249"
      //   },
      //   {
      //     label: "250 - 499",
      //     value: "250-499"
      //   },
      //   {
      //     label: "1000 - 2499",
      //     value: "1000-2499"
      //   },
      //   {
      //     label: "2500 - 4999",
      //     value: "2500-4999"
      //   },
      //   {
      //     label: "5000+",
      //     value: "5000+"
      //   },
      ]
    }
  },
  methods: {
    pluck(arr, key) {
      return arr.map(i => i[key])
    },
    loadSelectValues() {
      this.loadJobLevelValues()
      this.loadJobTitleValues()
      this.loadIndustryValues()
      this.loadCompanySizeValues()

    },
    loadJobLevelValues() {
      this.isLoading = true

      this.job_levels = []
      
      this.$http.get('/admin/v1/job_levels')
        .then(res => {
          const data = res.data
          const message = data.message

          if(!data.status) {
            this.$toast({
              component: ToastificationContent,
              job_level: 'top-right',
              props: {
                title: `Error`,
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: message,
              },
            })
          }
          else {
            const currentData = res.data.data
              
            currentData.forEach((value, index) => {
              this.job_levels.push({
                label: value.name,
                value: value.name
              });
            });

            this.job_level_values = this.pluck(this.job_levels, "value")

            this.job_levels.push({
              label: "Other",
              value: "Other"
            })

            if(!this.job_level_values.includes(this.userData.profile.job_level) && this.userData.profile.job_level !== null) {
              this.userData.profile.job_level_others = this.userData.profile.job_level
              
              this.userData.profile.job_level = "Other"
            }
          }

          this.isLoading = false
      })
    },
    loadJobTitleValues() {
      this.isLoading = true

      this.job_titles = []
      
      this.$http.get('/admin/v1/jobs')
        .then(res => {
          const data = res.data
          const message = data.message

          if(!data.status) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Error`,
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: message,
              },
            })
          }
          else {
            const currentData = res.data.data
              
            currentData.forEach((value, index) => {
              this.job_titles.push({
                label: value.name,
                value: value.name
              });
            });

            this.job_title_values = this.pluck(this.job_titles, "value")

            this.job_titles.push({
              label: "Other",
              value: "Other"
            })

            if(!this.job_title_values.includes(this.userData.profile.position) && this.userData.profile.position !== null) {
              this.userData.profile.position_others = this.userData.profile.position
              
              this.userData.profile.position = "Other"
            }
          }

          this.isLoading = false
      })
    },
    loadCompanySizeValues() {
      this.isLoading = true

      this.company_sizes = []
      
      this.$http.get('/admin/v1/company_sizes')
        .then(res => {
          const data = res.data
          const message = data.message

          if(!data.status) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Error`,
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: message,
              },
            })
          }
          else {
            const currentData = res.data.data
              
            currentData.forEach((value, index) => {
              this.company_sizes.push({
                label: value.name,
                value: value.name
              });
            });
          }

          this.isLoading = false
      })
    },
    loadIndustryValues() {
      this.isLoading = true

      this.industries = []
      
      this.$http.get('/admin/v1/industries')
        .then(res => {
          const data = res.data
          const message = data.message

          if(!data.status) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Error`,
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: message,
              },
            })
          }
          else {
            const currentData = res.data.data
              
            currentData.forEach((value, index) => {
              this.industries.push({
                label: value.name,
                value: value.name
              });
            });

            this.industry_values = this.pluck(this.industries, "value")

            this.industries.push({
              label: "Other",
              value: "Other"
            })

            if(!this.industry_values.includes(this.userData.profile.industry) && this.userData.profile.industry !== null) {
              this.userData.profile.industry_others = this.userData.profile.industry
              
              this.userData.profile.industry = "Other"
            }
          }

          this.isLoading = false
      })
    },
    save() {
      this.isLoading = true

      const requestData = {
        phone: this.userData.profile.phone,
        company: this.userData.profile.company,
        company_address: this.userData.profile.company_address,
        company_city_id: this.userData.company_city_id,
        company_phone: this.userData.profile.company_phone,
        company_email: this.userData.profile.company_email,
        company_size: this.userData.profile.company_size,
        industry: (this.userData.profile.industry_others != '' && this.userData.profile.industry == 'Other') ? this.userData.profile.industry_others : this.userData.profile.industry,
        // personal_email: this.userData.profile.personal_email,
        brand_name: this.userData.profile.brand_name,
        job_level: (this.userData.profile.job_level_others != '' && this.userData.profile.job_level == 'Other') ? this.userData.profile.job_level_others : this.userData.profile.job_level,
        position: (this.userData.profile.position_others != '' && this.userData.profile.position == 'Other') ? this.userData.profile.position_others : this.userData.profile.position,
        home_address: this.userData.profile.home_address,
      }

      this.$http.put('/admin/v1/users/' + this.userData.id + '/update_profile', requestData)
        .then(res => {         
          const data = res.data
          const message = data.message

          if(!data.status) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Error`,
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: message,
              },
            })
          }
          else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Success`,
                icon: 'CoffeeIcon',
                variant: 'success',
                text: message,
              },
            })

          }

          this.isLoading = false
      })
    },
    resetForm() {
      this.userData.profile.phone = ""
      this.userData.profile.company = ""
      this.userData.profile.company_address = ""
      this.userData.profile.company_city_id = ""
      this.userData.profile.company_state_id = ""
      this.userData.profile.company_country_id = ""
      this.userData.profile.company_phone = ""
      this.userData.profile.company_email = ""
      this.userData.profile.position = ""
      this.userData.profile.position_others = ""
      this.userData.profile.home_address = ""
      // this.userData.profile.personal_email = ""
      this.userData.profile.company_size = ""
      this.userData.profile.industry = ""
      this.userData.profile.industry_others = ""
      this.userData.profile.brand_name = ""
      this.userData.profile.job_level = ""
      this.userData.profile.job_level_others = ""
    },
    isValid() {
      return this.userData.profile.phone != "" && this.userData.profile.company != ""
    },
    loadCities(stateId) {
      this.isLoading = true

      this.cities = []

      if(stateId !== null) {
        this.$http.get('/cities?state_id=' + stateId, {})
          .then(res => {
            const data = res.data
            const message = data.message

            const currentData = data.data

            if(currentData !== null) {
              currentData.forEach((value, index) => {
                this.cities.push({
                  label: value.name,
                  value: value.id
                })
              })
            }

            if(!data.status) {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `Error`,
                  icon: 'CoffeeIcon',
                  variant: 'danger',
                  text: message,
                },
              })
            }

            this.isLoading = false
        })
      }
    },
    loadStates(countryId) {
      this.isLoading = true

      this.states = []
      this.cities = []

      if(countryId !== null) {
        this.$http.get('/states?country_id=' + countryId, {})
          .then(res => {         
            const data = res.data
            const message = data.message

            const currentData = data.data

            if(currentData !== null) {

              currentData.forEach((value, index) => {
                this.states.push({
                  label: value.name,
                  value: value.id
                })
              })

            }

            if(!data.status) {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `Error`,
                  icon: 'CoffeeIcon',
                  variant: 'danger',
                  text: message,
                },
              })
            }

            this.isLoading = false
        })
      }
    },
    loadCountries() {
      this.isLoading = true

      this.countries = []

      this.$http.get('/countries', {})
        .then(res => {         
          const data = res.data
          const message = data.message

          const currentData = data.data

          if(currentData !== null) {
            currentData.forEach((value, index) => {
              this.countries.push({
                label: value.name + " (+" + value.phonecode + ")",
                value: value.id
              })
            })
          }

          if(!data.status) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Error`,
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: message,
              },
            })
          }

          this.isLoading = false
      })
    }
  },
  created() {
    this.loadCountries()
    this.loadCities(this.userData.company_state_id)
    this.loadStates(this.userData.company_country_id)

    this.loadSelectValues()
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
